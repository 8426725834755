import React, { useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GatsbyImage } from "gatsby-plugin-image";
import "./gallery.css";

const GalleryModal = ({ setIsOpen, isOpen, gallery }) => {
  const images = [
    "https://pamelahudson.net/wp-content/uploads/2021/09/5540-Hwy-1-Ext-V2-Full-39-min-scaled.jpg",
    "https://pamelahudson.net/wp-content/uploads/2021/09/5540-Hwy-1-Ext-V2-Full-41-min-scaled.jpg",
    "https://pamelahudson.net/wp-content/uploads/2021/09/11500-County-Road-Full-6-min-scaled.jpg",
    "https://pamelahudson.net/wp-content/uploads/2021/09/33121-Navarro-Ridge-Full-46-min-scaled.jpg",
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  let slider = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (currentSlide, nextSlide) => setCurrentSlide(nextSlide),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      style={{
        maxWidth: "100%",
        width: "100%",
        height: "100%",
        margin: "0",
        backgroundColor: "black",
      }}
    >
      <ModalHeader
        style={{
          position: "relative",
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "3",
          border: "none",
        }}
      >
        {currentSlide + 1}/{gallery.length}
      </ModalHeader>
      <div
        style={{
          position: "absolute",
          zIndex: "4",
          top: "45vh",
          left: "10",
          right: "90%",
          transform: "translateY(-50%)",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        onClick={() => slider.slickPrev()}
      >
        <FontAwesomeIcon
          style={{
            fontSize: "26px",
          }}
          icon={faArrowLeft}
        />
      </div>
      <ModalBody
        style={{
          position: "absolute",
          zIndex: "2",
          top: "0",
          left: "50%",
          transform: "translateX(-50%)",
          maxWidth: "1600px",
          width: "100%",
          height: "100vh",
          margin: "0 auto",
        }}
      >
        <Slider {...settings} ref={(c) => (slider = c)}>
          {gallery.map((image, index) => (
            <div key={index}>
              <GatsbyImage
                image={image.image.asset.gatsbyImageData}
                alt={image.title}
                objectFit="cover"
                objectPosition="center"
                style={{ width: "100%", height: "80vh", objectFit: "cover" }}
              />
            </div>
          ))}
        </Slider>
      </ModalBody>{" "}
      <div
        style={{
          position: "absolute",
          zIndex: "4",
          top: "45vh",
          right: "10",
          left: "90%",
          transform: "translateY(-50%)",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "rgba(0,0,0,0.5)",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        onClick={() => slider.slickNext()}
      >
        <FontAwesomeIcon
          style={{
            fontSize: "26px",
          }}
          icon={faArrowRight}
        />
      </div>
      <ModalFooter className="modal_footer">
        {gallery.map((img, index) => (
          <div onClick={() => slider.slickGoTo(index)} key={index}>
            <GatsbyImage
              image={img.image.asset.gatsbyImageData}
              alt={img.title}
              objectFit="cover"
              objectPosition="center"
              className="img"
            />
          </div>
        ))}
      </ModalFooter>
    </Modal>
  );
};

export default GalleryModal;
