import React from "react";

const LuxuryDescription = ({ text1, text2, text3, text4 }) => {
  return (
    <div className="container-sm px-4">
      <div className="container-sm mx-auto py-4">
        <p style={{ color: "gray" }}>{text1}</p>
        <p style={{ color: "gray" }}>{text2}</p>
        <p style={{ color: "gray" }}>{text3}</p>
        <p style={{ color: "gray" }}>{text4}</p>
      </div>
    </div>
  );
};

export default LuxuryDescription;
