import React, { useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import LuxuryDescription from "../components/LuxuryRealEstateMendocino/luxuryDescription";
import RealEstateGallery from "../components/LuxuryRealEstateMendocino/gallery";
import GalleryModal from "../components/LuxuryRealEstateMendocino/Modal";
import { graphql } from "gatsby";

const LuxuryRealState = ({ data }) => {
  const seo = data.sanitySeo;
  const banner = data.sanityBanners.banner.asset.url;

  const gallery = data.allSanityMendocinoGallery.nodes;
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [isOpen, setIsOpen] = useState(false);
  const { ref, inView, entry } = useInView();

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            title="Luxury Real Estate Mendocino"
            bread="Luxury Real Estate Mendocino"
            buttontext=""
            src={banner}
            cover="100% 170%"
          />
          <LuxuryDescription
            text1="The Mendocino coast of northern California is home to unique and amazing ocean view luxury properties. The iconic and dramatic coastline of Mendocino is a majestic place that attracts visitors from around the world. Those lucky enough to find their dream home here are attracted to the luxury oceanfront homes that have been designed by some of the best architects in the world."
            text2="Pamela Hudson Real Estate and their skilled team of expert realtors have guided both buyers and sellers to successfully find or sell exceptional luxury properties on the coast for over 20 years."
            text3="Whether you are looking for off market listings in this fast paced and competitive market, or are ready to find the perfect oceanfront lot to build your dream home, or simply would like to find that perfect luxury ocean view vacation home, Mendocino and Pamela Hudson Real Estate is the best place to begin your search."
            text4="Pamela Hudson Real Estate represents the most discerning buyers and sellers on the Mendocino coast. Please reach out today to schedule a consultation or view our off market listings."
          />

          <RealEstateGallery
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            gallery={gallery}
          />
          <GalleryModal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            gallery={gallery}
          />
          <div ref={ref}>
            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default LuxuryRealState;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-b8c5f131-aec0-5500-a907-e9915205acd8" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    allSanityMendocinoGallery {
      nodes {
        title
        image {
          asset {
            gatsbyImageData(
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              width: 390
            )
          }
        }
      }
    }
    sanitySeo(pagehandle: { eq: "luxury-real-state-mendocino" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
