import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./gallery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RealEstateGallery = ({ setIsOpen, isOpen, gallery }) => {
  return (
    <div className="container-sm px-4">
      <div className="container-sm mx-auto p-5">
        <div className="row">
          {gallery.map(
            (item, index) =>
              item.image.asset && (
                <div
                  key={index}
                  className="col-sm-12 col-md-12 col-xl-4 mt-2 mb-4"
                >
                  <div
                    className="gallery_img_container"
                    style={{
                      position: "relative",
                    }}
                  >
                    {console.log(item)}
                    <GatsbyImage
                      image={item.image.asset.gatsbyImageData}
                      alt={item.title}
                      objectFit="cover"
                      objectPosition="center"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <div className="card_overlay">
                      <div className="card_overlay_content">
                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          className="card_overlay_icon"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default RealEstateGallery;
